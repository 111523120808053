var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"max-width":_vm.service.id == 1 || _vm.service.id == 4 ? 1300 : 1000},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-card',_vm._g({staticStyle:{"border":"1px solid var(--v-primary-base)"},on:{"click":_vm.fetchSubServices}},on),[_c('v-card-title',{staticClass:"mb-2 px-0 d-block text-center",staticStyle:{"font-size":"16px !important","text-decoration":"none !important"}},[_vm._v(" "+_vm._s(_vm.service.artist_type_id ? _vm.$t("appointments.type." + _vm.service.name) : _vm.service.name)+" ")]),_c('v-avatar',{staticClass:"gris3--text text-h2 text-uppercase mx-auto",staticStyle:{"display":"flex"},attrs:{"color":"primary","size":"60"}},[_vm._v(" "+_vm._s(_vm.service.name[0])+" ")]),_c('v-card-subtitle')],1)]}}]),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-card-title',[_vm._v(" "+_vm._s(_vm.service.artist_type_id ? _vm.$t("appointments.type." + _vm.service.name) : _vm.service.name)+" ")]),_c('div',{staticClass:"close"},[_c('v-btn',{attrs:{"icon":"","small":""},on:{"click":function($event){_vm.dialog = false}}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1),_c('v-card-text',[_c('v-row',{attrs:{"align":"center"}},[_c('v-spacer'),_c('Configuration',{attrs:{"service":_vm.service},on:{"update":function($event){return _vm.$emit('update')}}}),_c('NewSubServiceButton',{attrs:{"service":_vm.service},on:{"update":_vm.fetchSubServices}})],1)],1),_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.subservices},scopedSlots:_vm._u([{key:"item.image",fn:function(ref){
var item = ref.item;
return [(item.url)?_c('img',{attrs:{"src":item.url,"height":"50px"}}):_c('v-icon',{attrs:{"color":"primary"}},[_vm._v("$camera")])]}},{key:"item.time",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatTime()(item.time))+" ")]}},{key:"item.price",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$n(item.price, "currency"))+" "),(item.marketplace_pay == 'price')?_c('v-icon',{attrs:{"color":"success"}},[_vm._v("mdi-check")]):_vm._e()]}},{key:"item.paymentSignal",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$n(item.paymentSignalPrice, "currency"))+" "),(item.marketplace_pay == 'token_payment')?_c('v-icon',{attrs:{"color":"success"}},[_vm._v(" mdi-check ")]):_vm._e()]}},{key:"item.body_part",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(" "+_vm._s(_vm.$t("services." + item.body_part, { subpart: _vm.$t("body." + item.sub_body_part), }))+" ")])]}},{key:"item.marketplace",fn:function(ref){
var item = ref.item;
return [(item.marketplace)?_c('span',[_vm._v("Activo")]):_c('span',[_vm._v("Inactivo")])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"color":item.status === 'inscription' ? '' : '',"large":""}},'v-icon',attrs,false),on),[_vm._v(" mdi-dots-horizontal ")])]}}],null,true)},[_c('v-list',[_c('EditSubService',{attrs:{"subservice":item}}),_c('ConfigurationSubService',{attrs:{"subservice":item},on:{"update":_vm.fetchSubServices}}),_c('ArchiveSubService',{attrs:{"subservice":item},on:{"update":_vm.fetchSubServices}})],1)],1)]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }