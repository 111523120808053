<template>
  <v-dialog
    v-model="dialog"
    :max-width="service.id == 1 || service.id == 4 ? 1300 : 1000"
  >
    <template v-slot:activator="{ on }">
      <v-card
        style="border: 1px solid var(--v-primary-base)"
        v-on="on"
        @click="fetchSubServices"
      >
        <v-card-title
          class="mb-2 px-0 d-block text-center"
          style="font-size: 16px !important; text-decoration: none !important"
        >
          {{
            service.artist_type_id
              ? $t("appointments.type." + service.name)
              : service.name
          }}
        </v-card-title>

        <v-avatar
          color="primary"
          size="60"
          class="gris3--text text-h2 text-uppercase mx-auto"
          style="display: flex"
        >
          {{ service.name[0] }}
        </v-avatar>

        <v-card-subtitle></v-card-subtitle>
      </v-card>
    </template>
    <v-card>
      <v-card-title>
        {{
          service.artist_type_id
            ? $t("appointments.type." + service.name)
            : service.name
        }}
      </v-card-title>
      <div class="close">
        <v-btn icon @click="dialog = false" small>
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </div>

      <v-card-text>
        <v-row align="center">
          <v-spacer />
          <Configuration :service="service" @update="$emit('update')" />
          <NewSubServiceButton :service="service" @update="fetchSubServices" />
        </v-row>
      </v-card-text>
      <v-data-table :headers="headers" :items="subservices" class="elevation-1">
        <template v-slot:item.image="{ item }">
          <img v-if="item.url" :src="item.url" height="50px" />
          <v-icon v-else color="primary">$camera</v-icon>
        </template>
        <template v-slot:item.time="{ item }">
          {{ formatTime()(item.time) }}
        </template>

        <template v-slot:item.price="{ item }">
          {{ $n(item.price, "currency") }}
          <v-icon v-if="item.marketplace_pay == 'price'" color="success"
            >mdi-check</v-icon
          >
        </template>

        <template v-slot:item.paymentSignal="{ item }">
          {{ $n(item.paymentSignalPrice, "currency") }}
          <v-icon
            v-if="item.marketplace_pay == 'token_payment'"
            color="success"
          >
            mdi-check
          </v-icon>
        </template>

        <template v-slot:item.body_part="{ item }">
          <span>
            {{
              $t("services." + item.body_part, {
                subpart: $t("body." + item.sub_body_part),
              })
            }}
          </span>
        </template>
        <template v-slot:item.marketplace="{ item }">
          <span v-if="item.marketplace">Activo</span>
          <span v-else>Inactivo</span>
        </template>

        <template v-slot:item.actions="{ item }">
          <v-menu offset-y>
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                v-on="on"
                :color="item.status === 'inscription' ? '' : ''"
                v-bind="attrs"
                large
              >
                mdi-dots-horizontal
              </v-icon>
            </template>
            <v-list>
              <EditSubService :subservice="item" />

              <ConfigurationSubService
                :subservice="item"
                @update="fetchSubServices"
              />
              <ArchiveSubService
                :subservice="item"
                @update="fetchSubServices"
              />
            </v-list>
          </v-menu>
        </template>
      </v-data-table>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
  name: "SubServicesList",
  props: ["service"],
  components: {
    NewSubServiceButton: () =>
      import(
        "@/components/user/settings/configuracion/subservices/NewSubServiceButton"
      ),
    EditSubService: () =>
      import(
        "@/components/user/settings/configuracion/subservices/EditSubService"
      ),
    ArchiveSubService: () =>
      import(
        "@/components/user/settings/configuracion/subservices/ArchiveSubService"
      ),
    ConfigurationSubService: () =>
      import(
        "@/components/user/settings/configuracion/subservices/configuration/ConfigurationSubService"
      ),
    Configuration: () =>
      import("@/components/user/settings/configuracion/services/Configuration"),
  },

  data() {
    return {
      dialog: false,
      subservices: [],
      editedIndex: -1,
    };
  },
  computed: {
    headers() {
      let headeres = [
        {
          text: this.$t("services.image"),
          value: "image",
          align: "center",
        },
        {
          text: this.$t("services.subservice_code"),
          value: "reference",
          align: "center",
        },
        {
          text: this.$t("services.description"),
          value: "description",
          align: "center",
        },
        {
          text: this.$t("services.time_short"),
          value: "time",
          align: "center",
        },
        {
          text: this.$t("services.sesions"),
          value: "sesions",
          align: "center",
        },
        {
          text: this.$t("services.price_short"),
          value: "price",
          align: "center",
        },

        {
          text: this.$t("services.paymentSignal"),
          value: "paymentSignal",
          align: "center",
        },

        {
          text: this.$tc("services.marketplace", 2),
          value: "marketplace",
          align: "center",
        },
      ];
      console.log(this.service.id);

      if (this.service.id == 1 || this.service.id == 4)
        headeres.push({
          text: this.$t("services.body_part"),
          sortable: false,
          value: "body_part",
          align: "center",
        });

      headeres.push({
        text: this.$t("actions"),
        sortable: false,
        value: "actions",
        align: "center",
      });

      return headeres;
    },
  },

  methods: {
    ...mapActions("subservices", ["getSubServices"]),
    ...mapGetters("app", ["formatTime"]),
    fetchSubServices() {
      console.log("FETCH");
      this.loading = true;
      this.getSubServices({
        filters: {},
        pagination: {},
        service_id: this.service.id,
      }).then((subservices) => {
        this.subservices = subservices.data;
        console.log(this.subservices);
      });
    },
  },
};
</script>

<style></style>
